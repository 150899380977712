<template>
    <div>
        <div class="container">

            <!-- Mannschaftsbild -->
            <div v-if="teamData" class="team__photo">
                <img v-if="teamData.teamImage" :src="teamImage(team)" :alt="'Mannschaftsfoto' + team.teamName">
                <!-- <img v-else src="@/assets/teams/mannschaft-leverkusen.jpg" srcset="@/assets/teams/mannschaft-leverkusen@2x.jpg 2x" alt=""> -->
            </div>

            <!-- Logo & Name -->
            <div class="team__title">

                <!-- Logo -->
                <img class="team__title__logo" :src="teamLogo(team)" :alt="team.teamName">

                <!-- Name & Liga -->
                <div class="team__title__text">
                    <h1>{{ team.teamName }}</h1>
                    <span>{{ league.name }}</span>
                </div>

                <!-- Statistiken -->
                <div v-if="teamRanking" class="team__title__facts">
                    <div class="team__title__facts__item">
                        <div class="team__title__facts__item__value">
                            {{ teamRanking.position }}.
                        </div>
                        <div class="team__title__facts__item__label">
                            Platz
                        </div>
                    </div>
                    <div class="team__title__facts__item">
                        <div class="team__title__facts__item__value">
                            {{ teamRanking.matchesWon * 2 + teamRanking.matchesDraw }}:{{ teamRanking.matchesLost * 2 + teamRanking.matchesDraw }}
                        </div>
                        <div class="team__title__facts__item__label">
                            Punkte
                        </div>
                    </div>
                    <div class="team__title__facts__item">
                        <div class="team__title__facts__item__value">
                            {{ teamRanking.goalsFor }}:{{ teamRanking.goalsAgainst }}
                        </div>
                        <div class="team__title__facts__item__label">
                            Tore
                        </div>
                    </div>
                    <!-- <div v-if="teamSquad" class="team__title__facts__item">
                        <div class="team__title__facts__item__value">
                            {{ teamSquad.length }}
                        </div>
                        <div class="team__title__facts__item__label">
                            Spieler
                        </div>
                    </div> -->
                </div>

            </div>

        </div>

        <!-- Subnavigation -->
        <div class="tabs" id="tabs" v-bind:class="removeMargin ? 'tabs--nomargin' : ''">
            <div class="container">
                <div class="tabs__content">
                    <router-link :to="'/team/' + teamSlug(team) + '/' + league.id" :class="navigationClasses('/team/' + teamSlug(team) + '/' + league.id)">
                        Überblick
                    </router-link>
                    <router-link :to="'/team/' + teamSlug(team) + '/spielplan/' + league.id" :class="navigationClasses('/spielplan')">
                        Spielplan
                    </router-link>
                    <router-link :to="'/team/' + teamSlug(team) + '/tabelle/' + league.id" :class="navigationClasses('/tabelle')">
                        Tabelle
                    </router-link>
                    <router-link v-if="![1661, 1662].includes(league.fmpSeasonId)" :to="'/team/' + teamSlug(team) + '/kader/' + league.id" :class="navigationClasses('/kader')">
                        Kader
                    </router-link>
                    <router-link v-if="![1661, 1662].includes(league.fmpSeasonId)" :to="'/team/' + teamSlug(team) + '/transfers/' + league.id" :class="navigationClasses('/transfers')">
                        Transfers
                    </router-link>
                    <router-link :to="'/team/' + teamSlug(team) + '/statistiken/' + league.id" :class="navigationClasses('/statistiken')">
                        Statistiken
                    </router-link>
                    <router-link :to="'/team/' + teamSlug(team) + '/news/' + league.id" :class="navigationClasses('/news')">
                        News
                    </router-link>
                    <router-link :to="'/team/' + teamSlug(team) + '/infos/' + league.id" :class="navigationClasses('/infos')">
                        Vereinsinfos
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { teamLogoUrl, slugify } from '@/hbf-helper';

export default {
    name: 'TeamSubNavigation',
    props: {
        team: {
            type: Object,
            default() { null; } 
        },
        league: {
            type: Object,
            default() { null; } 
        },
        baseUrl: {
            type: String,
            default() { return ""; }
        },
        removeMargin: {
            type: Boolean,
        }
    },
    data() {
        return {
            seasonId: null,
            table: null,
            teamRanking: null,
            teamData: null,
            teamSquad: null,
        }
    },
    mounted() {
        this.init();
        this.scrollToActiveTab();
    },
    methods: {
        init() {
            this.seasonId = this.league.fmpSeasonId;
            this.$store.dispatch("fetchData",  {filename: "tables/table_" + this.seasonId + ".json"}).then( (res) => {
                this.table = res.table;
                this.teamRanking = this.table.find(t => t.fmpTeamId == this.team.fmpTeamId);
            });

            this.$store.dispatch("fetchData",  {filename: "teams/team_" + this.team.fmpTeamId + "_" + this.league.fmpSeasonId + ".json"}).then( (res) => {
                this.teamData = res;
                this.teamSquad = this.teamData.contracts.Current;
            });
        },
        navigationClasses(p) {
            if (window.location.href.indexOf(p) > -1) {
                return "tabs__item is-active";
            }
            return "tabs__item";
        },
        teamLogo(team) {
            return teamLogoUrl(team, 100, this.baseUrl, this.league.fmpSeasonId);
        },
        teamImage(team) {
            let url = new URL(team.teamImage);
            let doc_id = url.searchParams.get("id");
            return "https://hbf-helper.deinsportplatz.de/images/team/" + team.fmpTeamId + "/" + doc_id + "/" + this.league.fmpSeasonId ;
        },
        teamSlug(team) {
            return team.fmpTeamId + "--" + slugify(team.teamName);
        },
        scrollToActiveTab: function() {
            const tabs = document.getElementById('tabs');
            const activeTab = document.getElementsByClassName('is-active')[0];
            if (activeTab == null) { return; }
            const offsetActiveTab = activeTab.offsetLeft;

            if (offsetActiveTab > window.innerWidth - 48) {
                tabs.scrollLeft = offsetActiveTab - 24;
            }
        }
    }
}
</script>

<style scoped>

</style>